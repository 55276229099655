
<template>
  <div>
    <div class="theContact">
      <div class="the_contact">
        <el-row>
          <el-col :xl="8" :lg="8">
            <div class="the_contact_text"><h2>联系我们</h2>
              <h4>CONTACT</h4>
              <p class="the_p"></p></div>

          </el-col>
          <el-col :xl="8" style="text-align: left ;margin-left: 20px" :lg="8">
            <h3>天河有看头（武汉）科技有限公司</h3>
            <h5 @click="callPhone('027-59310008 ')">客服部号码：027-59310008</h5>
            <h5 @click="callPhone('027-59306818')">座机电话：027-59306818 </h5>
            <h5>官网：http://easymovies.youvips.cn</h5>
          </el-col>
          <el-col :xl="6" :lg="6">
            <div class="theSize">关注</div>
            <div class="flex-end">
              <div style="position: relative; align-items: center">
                <el-image :src="imgWb" :style="imgScan"></el-image>
                <el-image :src="img1" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave"
                          style="margin-right: 10px" class="imgIcon"></el-image>
              </div>
              <div style="position: relative; align-items: center">
                <el-image :src="imgQq" :style="imgScan2"></el-image>
                <el-image :src="img2" style="margin-right: 10px" @mouseenter="handleMouseEnter1"
                          @mouseleave="handleMouseLeave1" class="imgIcon"></el-image>
              </div>
              <div style="position: relative; align-items: center">
                <el-image :src="imgWx" :style="imgScan3"></el-image>
                <el-image :src="img3" style="margin-right: 10px" @mouseenter="handleMouseEnter2"
                          @mouseleave="handleMouseLeave2" class="imgIcon"></el-image>
              </div>
            </div>
          </el-col>
          <el-col class="thePhone">
            <el-button type="primary" size="large" @click="callPhone('027-59306818')" style="width: 80%;">一键拨打电话
            </el-button>
          </el-col>
        </el-row>

        <!--      <p style="color:#00af3c;margin:30px" @click="callPhone('027-59306818 ')">拨打电话！</p>-->
      </div>
    </div>

    <div class="the_footer">
      <p style="margin-top: 0px"> ©2022 天河点播. All Rights Reserved. <a href="https://beian.miit.gov.cn/"
                                                                          target="_Blank">鄂ICP备17000215号-2</a> <a
          href="https://beian.mps.gov.cn/#/query/webSearch" target="_Blank">公网备案号42018502000685</a></p>
      <p><a href="https://pic.youvips.cn/website/z1.jpg" target="_Blank">广播电视节目制作经营许可证（鄂）字第00327号</a> <a
          href="https://pic.youvips.cn/website/z2.jpg" target="_Blank"> 网络文化经营许可证鄂网文【2017】8678-197号</a><a
          href="https://pic.youvips.cn/website/z3.jpg" target="_Blank">出版物经营许可证鄂批字第0715号</a> <a
          href="https://pic.youvips.cn/website/z4.jpg" target="_Blank">营业性演出许可证</a></p>
      <p><a href="https://pic.youvips.cn/website/z5.jpg" target="_Blank">增值电信业务经营许可证</a> <a
          href="https://pic.youvips.cn/website/z6.jpg" target="_Blank">电影发行经营许可证</a> <a
          href="https://pic.youvips.cn/website/z7.jpg" target="_Blank"> 食品经营许可证</a> <a
          href="https://pic.youvips.cn/website/yyzz.jpg" target="_Blank">营业执照</a></p>
      <p style="margin-bottom: 0">天河点播 看我所爱</p>
    </div>

    <el-affix position="bottom" >
      <div v-if="isPhone" style="background-color: white; width: 100%;display: flex;justify-content: space-around;padding: 10px 0 10px 0"  >
        <div  @click="goToHome" >
          <el-image :src="home" style="width: 30px;">
          </el-image>
          <div class="text">首页</div>
        </div>
        <div  @click="callPhone('027-59310008')">

          <el-image :src="phone" @mouseenter="handleTheMouseEnter" @mouseleave="handleTheMouseLeave" style="width: 30px;">

          </el-image>
          <div class="text">电话</div>
        </div>

      </div>
    </el-affix>
  </div>

</template>

<script setup>
import {onMounted, ref} from "vue";
import router from "@/router";

const img1 = ref("https://pic.youvips.cn/website/img_9_1.png")
const img2 = ref("https://pic.youvips.cn/website/img_9_2.png")
const img3 = ref("https://pic.youvips.cn/website/img_9_3.png")
const img4 = ref("http://img.youvips.cn/tianhe/img/icon/thephone11.png")
const imgWb = ref("https://pic.youvips.cn/website/weibo.png")
const imgQq = ref("https://pic.youvips.cn/website/qq.png")
const imgWx = ref("https://pic.youvips.cn/website/weixin.jpg")


const goToHome = () => {
  router.push({name: '首页'})
  
}
// 联系我们板块
const imgScan = ref(
    "  display: none;\n" +
    "  width: 100px;\n" +
    "  height: 100px;\n" +
    "  position: absolute;\n" +
    "  left:-98px;\n" +
    "  top: -20px;"
)
const imgScan2 = ref(
    "  display: none;\n" +
    "  width: 100px;\n" +
    "  height: 100px;\n" +
    "  position: absolute;\n" +
    "  left:-98px;\n" +
    "  top: -20px;"
)
const imgScan3 = ref(
    "  display: none;\n" +
    "  width: 100px;\n" +
    "  height: 100px;\n" +
    "  position: absolute;\n" +
    "  left:-98px;\n" +
    "  top: -20px;"
)
const handleMouseEnter = () => {
  img1.value = "https://pic.youvips.cn/website/img_9_1_h.png"
  imgScan.value = "  display: block;\n" +
      "  width: 100px;\n" +
      "  height: 100px;\n" +
      "  position: absolute;\n" +
      "  left:-98px;\n" +
      "  top: -20px; animation: slide-in 1s forwards;"
}
const handleMouseEnter1 = () => {
  img2.value = "https://pic.youvips.cn/website/img_9_2_h.png"
  imgScan2.value = "  display: block;\n" +
      "  width: 100px;\n" +
      "  height: 100px;\n" +
      "  position: absolute;\n" +
      "  left:-98px;\n" +
      "  top: -20px; animation: slide-in 1s forwards;"

}
const handleMouseEnter2 = () => {
  img3.value = "https://pic.youvips.cn/website/img_9_3_h.png"
  imgScan3.value = "  display: block;\n" +
      "  width: 100px;\n" +
      "  height: 100px;\n" +
      "  position: absolute;\n" +
      "  left:-98px;\n" +
      "  top: -20px; animation: slide-in 1s forwards;"
}
const handleMouseLeave = () => {
  img1.value = "https://pic.youvips.cn/website/img_9_1.png"
  imgScan.value = "  display: none;\n" +
      "  width: 100px;\n" +
      "  height: 100px;\n" +
      "  position: absolute;\n" +
      "  left:-98px;\n" +
      "  top: -20px;"
}
const handleMouseLeave1 = () => {
  img2.value = "https://pic.youvips.cn/website/img_9_2.png"
  imgScan2.value = "  display: none;\n" +
      "  width: 100px;\n" +
      "  height: 100px;\n" +
      "  position: absolute;\n" +
      "  left:-98px;\n" +
      "  top: -20px; animation: slide-in 1s forwards;"

}
const handleMouseLeave2 = () => {
  img3.value = "https://pic.youvips.cn/website/img_9_3.png"
  imgScan3.value = "  display: none;\n" +
      "  width: 100px;\n" +
      "  height: 100px;\n" +
      "  position: absolute;\n" +
      "  left:-98px;\n" +
      "  top: -20px; animation: slide-in 1s forwards;"
}
// 打电话
const callPhone = (phoneNumber) => {
  window.location.href = 'tel://' + phoneNumber
}
const isPhone = ref()
const screenWidth = ref()
const detectionScreen = () => {
  if (screenWidth.value > 1000) {
    isPhone.value = false

  } else if (screenWidth.value < 450) {
    isPhone.value = true

  } else {
    isPhone.value = false

  }

}

window.onresize = () => {
  screenWidth.value = document.body.clientWidth;
  detectionScreen()
};
/**
 * 屏幕大小监听
 * */
onMounted(() => {
  screenWidth.value = document.body.clientWidth;
  detectionScreen()
})
const home = ref('https://pic.youvips.cn/website/btn15_1.png')
const phone = ref('https://pic.youvips.cn/website/btn16.png')
const handleTheMouseEnter = () => {
  phone.value = 'https://pic.youvips.cn/website/btn16_1.png'
}
const handleTheMouseLeave = () => {
  phone.value = 'https://pic.youvips.cn/website/btn16.png'
}


const myElement = ref();
// const height = myElement.value.offsetHeight;
console.log('元素高度：', myElement.value);



</script>

<style scoped>
.text{
  font-size: 12px;
}
.the_p {
  background-color: #42b983;
  width: 60px;
  height: 2px;
  margin: 0
}

@media screen and (max-width: 450px) {
  .thePhone {
    display: block;
  }

  .flex-end {
    display: none;
  }

  .the_contact {
    margin-top: 20px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
}

.theSize {
  display: none;
}

@media screen and (min-width: 450px) and (max-width: 1200px) {
  .flex-end {
    display: none;
    justify-content: flex-end;
    width: 80%;
  }

  .theSize {
    font-size: 12px;
  }


  .thePhone {
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .theSize {
    margin-left: 60px;
    text-align: left;
    display: block;
    font-size: 15px;

  }

  .the_contact {
    margin-top: 20px;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
    width: 80%;
  }

  .thePhone {
    display: none;

  }
}

.imgIcon {
  width: 40px;
  height: 40px;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.the_footer {
  color: #d3dce6;
  width: 100%;
  bottom: 0;
  background-color: #2c3e50;
  text-align: center;
  font-size: 13px;
}

.the_footer a {
  color: #d3dce6;
  text-decoration: none;
}

a:hover {
  color: whitesmoke;
  text-decoration: none;
  font-size: 13px;
  text-shadow: 0 0 2px rgba(248, 245, 245, 0.98);
}

.theContact {
  padding: 30px 0 30px 0;
  margin-top: 30px;
  width: 100%;
  background-color: #f8f8f8;
}

.the_contact_text {
  margin-left: 20px;
  text-align: left;

}

.the_contact_text h2 {
  margin-bottom: 0;
}

.the_contact_text h4 {
  margin-top: 5px;
}


.el-icon el-sub-menu__icon-more {
  background-image: url('http://img.youvips.cn/tianhe/img/icon/_menu_.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 22px;
  height: 22px;
}
</style>