<template>
  <div class="common-layout">
    <el-container>
      <el-header height="80"  style="  padding: 0;" >
        <el-affix position="top" :offset="1" >
          <div style="background-color: black" ><Headers/></div></el-affix>
      </el-header>
      <router-view></router-view>
      <div v-if="!$route.meta.showValues">
        <Footer/>
      </div>

    </el-container>


  </div>
</template>

<script>
import Headers from './layout/headers/index.vue'
import Footer from './layout/footer/index.vue'

export default {
  name: "App",
  components: {
    Headers,
    Footer,
  },



}



const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

</script>

import { isDark } from '~/composables/dark'

<style lang="scss">
* :not(h1,h2,h3,h4,h5) {
  margin: 0;
  padding: 0;

}
body{
  width: 100%;
}
body, html {
  overflow-x: hidden;
}
//*{
//  outline: solid #f00 1px !important;
//  background: #000 !important;
//  color: #fff !important;
//}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.common-layout {


}


nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}


</style>
