<template>

  <div>
    <el-menu
        router

        active-text-color="#ffd04b"
        mode="horizontal"
        background-color="black"
        text-color="whitesmoke"
        style="margin: auto;padding-top: 10px;border-bottom: 0"
        class="theMenu"
        unique-opened



    >

      <el-menu-item index="/">
        <img
            style="width: 75px"
            src="https://pic.youvips.cn/logo_new_1.png"
            alt="天河点播"
        />
      </el-menu-item>
      <div v-if="!isPhone" class="flex-grow"/>


      <el-menu-item v-if="!isPhone" index="/" >首页</el-menu-item>

      <el-menu-item index="/welfareMall" >福利商城</el-menu-item>
<!--      <el-menu-item index="/talentRecruitment" >人才招聘</el-menu-item>-->
      <el-sub-menu index="/DemandCinema" >
        <template #title>点播院线</template>
        <el-menu-item index="/BrandIntroduction" >品牌介绍</el-menu-item>
        <!--<el-menu-item index="/CaseShow">门店展示</el-menu-item>-->
        <el-menu-item index="/ContactFranchise" >联系加盟</el-menu-item>
      </el-sub-menu>

      <el-menu-item index="/aboutUs" >关于我们</el-menu-item>
      <!--      <el-menu-item index="/solution">解决方案</el-menu-item>-->
      <el-menu-item index="/newsInformation" >新闻资讯</el-menu-item>

    </el-menu>
    <div v-if="theIndex"
         style="width: 100% ;height:100vh;position: absolute;z-index:3;background-color: rgba(0,0,0,0.4);display: flex;align-items: center;justify-content: center"></div>
  </div>
</template>


<style scoped>

.logo {
  width: 70px;
  height: 70px;


}

.el-menu-demo {

}

.flex-grow {
  flex-grow: 0.8;
}

@media screen and (max-width: 450px) {
  .theMenu {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  ::v-deep(.el-menu--popup) {
    min-width: 0 !important;
  }
  ::v-deep(.el-menu--horizontal.el-menu--popup-container) {

  }
  ::v-deep(.el-sub-menu .el-icon.el-sub-menu__icon-more) {
    background-image: url('https://pic.youvips.cn/themenumenu.png') !important;
    background-position: center !important;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    width: 30px !important;
    height: 30px !important;
    z-index: 999;

  }



  ::v-deep(.el-icon svg) {
    display: none !important;
  }

}

@media screen and (min-width: 450px) and (max-width: 1200px) {
  .theMenu {
    width: 90%;

  }

  ::v-deep(.el-sub-menu .el-icon.el-sub-menu__icon-more) {
    background-image: url('https://img.youvips.cn/tianhe/img/icon/themenumenu.png') !important;
    background-position: center !important;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    width: 30px !important;
    height: 30px !important;
    z-index: 999;

  }

  ::v-deep(.el-icon svg) {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .theMenu {
    width: 80%;
  }
}


</style>
<script setup>
import {computed, onMounted, ref} from "vue";
import router from "@/router";
import {useRoute} from "vue-router";
import store from "@/store";

const route = useRoute()

console.log(route.path)
const active = ref('/')
const theIndex = ref(
    false
)
const ellipsis = ref()
const currentPath = ref('')
const getThisPageRouterPath = ref('')

// 路由路径赋予



const theClose = () => {
  theIndex.value = false
  console.log("遮罩层关闭了")
}
const theActive=ref("/")
const handleSelect = (keyPath) => {
  console.log("hhh"+keyPath)
  theActive.value=keyPath
  theIndex.value = false

}

const theOpen = (index) => {

  theIndex.value = true
  console.log("遮罩层打开了")
  getThisPageRouterPath.value = router.currentRoute.value.fullPath

}

const isPhone = ref()
const screenWidth = ref()

window.onresize = () => {
  screenWidth.value = document.body.clientWidth;
  detectionScreen()

};
/**
 * 屏幕大小监听
 * */
onMounted(() => {
  screenWidth.value = document.body.clientWidth;
  detectionScreen()
  console.log(screenWidth.value)
})

const detectionScreen = () => {
  if (screenWidth.value > 1000) {
    isPhone.value = false
    ellipsis.value = false

  } else if (screenWidth.value < 450) {
    isPhone.value = true
    ellipsis.value = true

  } else {
    isPhone.value = false
    ellipsis.value = true
  }

}

currentPath.value = router.currentRoute.value.fullPath
console.log("xxx"+currentPath.value )


</script>