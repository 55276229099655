const Mock = require("mockjs")
const Random = Mock.Random
export const newsData = Mock.mock("/data/newsList", "post", {
    // 属性 list 的值是一个数组，随机生成 1 到 10 个元素
    "list|1-10": [
        {
            "title": Random.string(5),
            // 随机生成图片 Random.image( size, background, foreground, format, text )
            "pic": Random.image('200x100', '#16d46b', '#fff', 'png', 'Hello'), // "http://dummyimage.com/200x100/16d46b/fff.png&text=Hello"
            // 随机日期
            "createTime": Random.datetime('yyyy-MM-dd'), // "2017-05-01"
            "content": Random.string(40),
            "editTime": Random.datetime('yyyy-MM-dd'),

        }

    ],
    code: 200,
    message: 'ok',

})

export const classData = Mock.mock("/data/caseList", "post", {
    // 属性 list 的值是一个数组，随机生成 1 到 10 个元素
    "list|10-20": [
        {
            "title": Random.string(10),
            // 随机生成图片 Random.image( size, background, foreground, format, text )
            "pic|1": ["http://pic.youvips.cn/website/guwm.png","http://pic.youvips.cn/website/guwm.png"],
            // 随机日期
            "createTime": Random.datetime('yyyy-MM-dd'), // "2017-05-01"
            "content": Random.string(40),
            "editTime": Random.datetime('yyyy-MM-dd'),

        }

    ],
    code: 200,
    message: 'ok',

})