import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import "@/mockjs/index";




const  app=createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(ElementPlus, {
    locale: zhCn,
})
app.mount('#app')
const  debounce=(fn,delay)=>{
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context,args)
        },delay);
    }
}
const _ResizeObserver=window.ResizeObserver
window.ResizeObserver=class ResizeObserver extends  _ResizeObserver{
    constructor(callback) {
        callback=debounce(callback,16)
        super(callback);
    }
}