import {createRouter, createWebHashHistory} from 'vue-router'
import Footer from "@/layout/footer/index.vue";


const routes = [
    {
        path: '/',
        name: '首页',
        component: () => import('../views/HomeView.vue')
    },
    {
        path: '/DemandCinema',
        name: '点播影院',
        component: () => import('../views/DemandCinema.vue'),
        children: [
            {
                path: '/BrandIntroduction',
                name: '品牌介绍',
                component: () => import('../views/BrandIntroduction.vue'),
                meta: {
                    showValues: true
                },


            },
            {
                path: '/ContactFranchise',
                name: '门店加盟',
                component: () => import('../views/ContactFranchise.vue')
            },
            {
                path: '/CaseShow',
                name: '门店展示',
                component: () => import('../views/CaseShow.vue')
            }
        ]
    },

    {
        path: '/welfareMall',
        name: '福利商城',
        component: () => import('../views/WelfareMall.vue')
    },
    {
        path: '/newsInformation',
        name: '新闻资讯',
        component: () => import('../views/NewsInformation.vue'),
    },
    {
        path: '/talentRecruitment',
        name: '人才招聘',
        component: () => import( '../views/TalentRecruitment.vue')
    },
    {
        path: '/solution',
        name: '解决方案',
        component: () => import( '../views/Solution.vue')
    },

    {
        path: '/aboutUs',
        name: '关于我们',
        component: () => import('../views/AboutUs.vue')
    }, {
        path: '/newsDetail/:id?',
        name: '新闻详情',
        component: () => import('../views/NewsDetail.vue')
    },
    {
        path: '/caseDetail/:id?',
        name: '案例详情',
        component: () => import('../views/CaseDetail.vue')
    },


]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router




